import React, { useState } from 'react'
import useQueryParams from '../../hooks/useQueryParams'
import { useQuery } from 'react-query'
import QueryKeys from '../../utils/constants/queryKeys'
import LoaderDots from '../../components/loaders/LoaderDots/LoaderDots'
import getSingleTeam from '../../Apis/team/getSingleTeam'
import { useTranslate } from 'react-admin'
import teamStatisticsPageStyles from './teamStatisticsPage.styles'
import StatsGrid from './StatsGrid'
import PlayersTable from './PlayersTable'
import COLORS from '../../utils/constants/colors'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import NoStatsAvailable from './NoStatsAvalaiblePage'
import {
  makeStyles,
  Card,
  CardContent,
  Divider,
  Typography,
  CardMedia,
  IconButton,
  TableContainer,
  Paper,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Container,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import MyToggleButton from '../../components/MyToggleButton/MyToggleButton'
import StatsInfoDialog from './StatsInfoDialog'
import StatsNavigationPage from './StatsNavigationPage'
import ContainerPadding from '../../components/containerPadding/containerPadding'
import getTeamStats from '../../Apis/stats/getTeamStats'
import statsInfo from '../../utils/constants/statsInfo'
import DefaultOrgIcon from '../../assets/icons/DefaultOrgIcon'

const TeamStatisticsPage = () => {
  const useStyles = makeStyles(teamStatisticsPageStyles)
  const [toggleValue, setToggleValue] = useState('average')
  const [open, setOpen] = useState(false)
  const [selectedGames, setSelectedGames] = useState(1000)
  const translate = useTranslate()
  const classes = useStyles()
  const queryParams = useQueryParams()
  const { data: team } = useQuery(
    [QueryKeys.GET_SINGLE_TEAM],
    () => getSingleTeam(queryParams?.team),
    {
      enabled: Boolean(queryParams?.team),
    },
  )

  const { data: stats, isFetching } = useQuery(
    [QueryKeys.GET_TEAM_STATS, selectedGames],
    () => getTeamStats({ teamId: queryParams?.team, games: selectedGames }),
    {
      enabled: Boolean(queryParams?.team),
    },
  )

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleGamesChange = (event) => {
    setSelectedGames(event.target.value)
  }

  return (
    <Container>
      {isFetching ? (
        <ContainerPadding paddingValue='45%'>
          <LoaderDots
            style={{
              fill: COLORS.orange,
              width: 70,
              height: 70,
            }}
          />
        </ContainerPadding>
      ) : (
        <ContainerPadding paddingValue='2%' className={classes.statsContainer}>
          <Card className={classes.teamCard}>
            {team?.teamLogo ? (
              <CardMedia className={classes.teamLogo} image={team.teamLogo} />
            ) : (
              <DefaultOrgIcon className={classes.teamLogo} />
            )}
            <CardContent className={classes.teamCardContent}>
              <Box className={classes.infoBox}>
                <Typography variant='h5' className={classes.teamName}>
                  {team?.name}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100px' }}>
                  <Table
                    sx={{
                      width: '100%',
                      tableLayout: 'fixed',
                      '& td, & th': {
                        padding: '0px 0px',
                        borderBottom: 'none',
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow style={{ backgroundColor: 'transparent' }}>
                        <TableCell
                          style={{
                            width: '50%',
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            textAlign: 'center',
                            borderRight: '1px solid black',
                            borderBottom: 'none',
                            padding: '0px 10px',
                            margin: '0px 0px',
                          }}
                        >
                          <Typography className={classes.teamVictories}>
                            {translate('ra.text.won')}
                          </Typography>
                        </TableCell>
                        <TableCell
                          style={{
                            width: '50%',
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            textAlign: 'center',
                            borderBottom: 'none',
                            padding: '0px 0px',
                            margin: '0px 0px',
                          }}
                        >
                          <Typography className={classes.teamVictories}>
                            {translate('ra.text.lost')}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          style={{
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            textAlign: 'center',
                            borderRight: '1px solid black',
                            padding: '0px 10px',
                            margin: '0px 0px',
                          }}
                        >
                          <Typography sx={{ fontSize: '3rem', fontWeight: 'bold' }}>
                            {stats?.data?.teamStats?.wins || 0}
                          </Typography>
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            textAlign: 'center',
                            padding: '0px 10px',
                            margin: '0px 0px',
                          }}
                        >
                          <Typography sx={{ fontSize: '3rem', fontWeight: 'bold' }}>
                            {stats?.data?.teamStats?.gameCount - stats?.data?.teamStats?.wins || 0}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </CardContent>
          </Card>
          <StatsNavigationPage>
            {stats?.data === null || stats?.data === '' ? (
              <NoStatsAvailable />
            ) : (
              <>
                <div className={classes.tabMenu}>
                  <MyToggleButton
                    left={{
                      value: 'average',
                      label: translate('ra.buttons.average'),
                    }}
                    right={{
                      value: 'accumulate',
                      label: translate('ra.buttons.accumulate'),
                    }}
                    setToggleValue={setToggleValue}
                    toggleValue={toggleValue}
                  />
                  <div className={classes.share}>
                    <InputLabel id='games-select-label'>
                      {translate('ra.text.games')}
                      <Select
                        labelId='games-select-label'
                        id='games-select'
                        value={selectedGames}
                        onChange={handleGamesChange}
                      >
                        <MenuItem value={1000}>{translate('ra.text.allGames')}</MenuItem>
                        <MenuItem value={1}>{translate('ra.text.lastGame')}</MenuItem>
                        <MenuItem value={5}>{translate('ra.text.last5Games')}</MenuItem>
                        <MenuItem value={10}>{translate('ra.text.last10Games')}</MenuItem>
                      </Select>
                    </InputLabel>
                  </div>
                </div>
                <StatsGrid stats={stats.data.teamStats} showValue={toggleValue} />
                <Divider
                  variant='middle'
                  style={{
                    margin: '15px 0 15px 0 ',
                  }}
                />
                <div>
                  <span>{translate('ra.text.selectedPlayers')}</span>
                  <IconButton onClick={handleClickOpen}>
                    <ErrorOutlineIcon />
                  </IconButton>
                </div>
                <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto' }}>
                  <PlayersTable players={stats.data.playerStats} showValue={toggleValue} />
                </TableContainer>

                <StatsInfoDialog open={open} onClose={handleClose} statsInfo={statsInfo} />
              </>
            )}
          </StatsNavigationPage>
        </ContainerPadding>
      )}
    </Container>
  )
}

export default TeamStatisticsPage
