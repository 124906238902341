import axios from 'axios'
import generalHeader from '../../utils/constants/generalHeader'
import { BASE_URL } from '../../utils/constants/url'
import { CLIENT_TYPE_HEADER } from '../../utils/constants/ClientTypeHeader'

export const googleLogin = async (data) => {
  const url = `${BASE_URL}/auth/google`

  const response = await axios.post(
    url,
    { googleUserCredentials: data.credential, platform: CLIENT_TYPE_HEADER },
    {
      headers: generalHeader(),
      withCredentials: true,
    },
  )
  if (response.status !== 200) throw new Error(response.status)
  return response.data
}
