import React from 'react'
import { Route } from 'react-router'
import * as Sentry from '@sentry/react'
import ROUTES from '../constants/routes'
import withAuthHOC from '../../components/withAuthHOC/withAuthHOC'

const routesWithAuth = () => {
  const SentryRoute = Sentry.withSentryRouting(Route)

  return ROUTES.map((route) => {
    const {
      exact,
      path,
      component,
      noLayout,
      allowedOrgAddons,
      allowedScopes,
      allowedUserOrgRoles,
      payLocked,
    } = route || {}

    return (
      <SentryRoute
        key={path}
        exact={exact}
        path={path}
        component={withAuthHOC(
          allowedOrgAddons,
          allowedScopes,
          allowedUserOrgRoles,
          payLocked,
        )(component)}
        noLayout={noLayout}
      />
    )
  })
}

export default routesWithAuth
