import React from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core'
import { useTranslate } from 'react-admin'
import perQuarterTableStyles from './perQuarterTable.styles'

const zipLongest = (fillValue = null, ...arrays) => {
  const maxLength = Math.max(...arrays.map((arr) => arr.length))
  return Array.from({ length: maxLength }, (_, i) =>
    arrays.map((arr) => (i < arr.length ? arr[i] : fillValue)),
  )
}

const PerQuarterTable = ({ teams, gameTimeInfo, cummulative = false }) => {
  const useStyles = makeStyles(perQuarterTableStyles)
  const translate = useTranslate()
  const classes = useStyles()

  const getHighestPeriodScoreTeamIds = (teams) => {
    return teams[0].periodScores.map((score, index) => {
      const score1 = score
      const score2 = teams[1].periodScores[index]

      if (score1 > score2) return teams[0].gameTeamId
      if (score2 > score1) return teams[1].gameTeamId
      return null
    })
  }

  const getHighestOverallScoreTeamIds = (teams) => {
    return teams[0].endPeriodScores.map((score, index) => {
      const score1 = score
      const score2 = teams[1].endPeriodScores[index]

      if (score1 > score2) return teams[0].gameTeamId
      if (score2 > score1) return teams[1].gameTeamId
      return null
    })
  }

  const maxPeriodToShow = Math.max(gameTimeInfo.period, 4)
  const periodsToShow = Array.from({ length: maxPeriodToShow }, (v, k) => k + 1)
  const highestPeriodScoreGameTeamIds = getHighestPeriodScoreTeamIds(teams)
  const highestOverallScoreGameTeamIds = getHighestOverallScoreTeamIds(teams)

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell className={classes.tableHeader}>{translate('ra.text.team')}</TableCell>
            {periodsToShow.map((period) => (
              <TableCell key={period} className={classes.tableHeader}>
                {period <= 4 ? `${translate('ra.text.quarter')} ${period}` : `OT${period - 4}`}{' '}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {teams.map((team) => {
            const scoresByPeriod = cummulative ? team.endPeriodScores : team.periodScores
            const winningGameTeamIdByPeriod = cummulative
              ? highestOverallScoreGameTeamIds
              : highestPeriodScoreGameTeamIds
            return (
              <TableRow key={team.teamId}>
                <TableCell style={{ fontSize: '16px', textAlign: 'center' }}>{team.name}</TableCell>
                {zipLongest(null, periodsToShow, scoresByPeriod, winningGameTeamIdByPeriod).map(
                  ([period, score, winningGameTeamId]) => {
                    return (
                      <TableCell key={`${team.teamId}-${period}`} className={classes.tableCell}>
                        <span
                          className={classes.spanContainer}
                          style={{
                            backgroundColor:
                              team.gameTeamId === winningGameTeamId ? '#D3D3D3' : 'transparent',
                          }}
                        >
                          {score === 0 || score ? score : '-'}
                        </span>
                      </TableCell>
                    )
                  },
                )}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

PerQuarterTable.propTypes = {
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      teamId: PropTypes.string,
      name: PropTypes.string,
      periodScores: PropTypes.shape({
        1: PropTypes.number,
        2: PropTypes.number,
        3: PropTypes.number,
        4: PropTypes.number,
        5: PropTypes.number,
        6: PropTypes.number,
        7: PropTypes.number,
        8: PropTypes.number,
        9: PropTypes.number,
      }),
      cumulativePeriodScores: PropTypes.shape({
        1: PropTypes.number,
        2: PropTypes.number,
        3: PropTypes.number,
        4: PropTypes.number,
        5: PropTypes.number,
        6: PropTypes.number,
        7: PropTypes.number,
        8: PropTypes.number,
        9: PropTypes.number,
      }),
      score: PropTypes.number,
      teamPlayerStats: PropTypes.arrayOf(
        PropTypes.shape({
          playerId: PropTypes.string,
          fullName: PropTypes.string,
          opponentName: PropTypes.string,
          gamesPlayed: PropTypes.number,
          gamesStarted: PropTypes.number,
          minutes: PropTypes.string,
          gameStats: PropTypes.shape({
            points: PropTypes.number,
            avgPoints: PropTypes.number,
            twoPointer: PropTypes.number,
            avgTwoPoints: PropTypes.number,
            totalTwoPointsAttempts: PropTypes.number,
            threePointer: PropTypes.number,
            avgThreePoints: PropTypes.number,
            totalThreePointsAttempts: PropTypes.number,
            freeThrows: PropTypes.number,
            avgFreeThrows: PropTypes.number,
            totalFreeThrowsAttempts: PropTypes.number,
            offensiveRebounds: PropTypes.number,
            avgOffensiveRebounds: PropTypes.number,
            defensiveRebounds: PropTypes.number,
            avgDefensiveRebounds: PropTypes.number,
            totalRebounds: PropTypes.number,
            avgRebounds: PropTypes.number,
            assists: PropTypes.number,
            avgAssists: PropTypes.number,
            steals: PropTypes.number,
            avgSteals: PropTypes.number,
            turnOvers: PropTypes.number,
            avgTurnOvers: PropTypes.number,
            blocksAgainst: PropTypes.number,
            avgBlocksAgainst: PropTypes.number,
            foulsCommited: PropTypes.number,
            avgFoulsCommited: PropTypes.number,
            foulsDrawn: PropTypes.number,
            avgFoulsDrawn: PropTypes.number,
            pir: PropTypes.number,
            avgPIR: PropTypes.number,
          }),
        }),
      ),
    }),
  ),
  currentPeriod: PropTypes.number,
  sum: PropTypes.bool,
}

PerQuarterTable.defaultProps = {
  sum: false,
}

export default PerQuarterTable
