import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Card,
  CardContent,
  Typography,
  Box,
  makeStyles,
  CardMedia,
} from '@material-ui/core'
import { useTranslate } from 'react-admin'
import gameStatsInfoStyles from './gameStatsInfo.styles'
import DefaultOrgIcon from '../../assets/icons/DefaultOrgIcon'
import STATS_GAME_STATUS from '../../utils/constants/statsGameStatus'
import COLORS from '../../utils/constants/colors'

const GameStatsInfo = ({ gameStats }) => {
  const useStyles = makeStyles(gameStatsInfoStyles)
  const translate = useTranslate()
  const classes = useStyles()

  if (!gameStats || gameStats?.teams.length < 2) {
    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant='h5'>Error: Missing required data.</Typography>
        </CardContent>
      </Card>
    )
  }

  const homeTeam = gameStats?.teams[0]
  const awayTeam = gameStats?.teams[1]

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Container className={classes.logoContainer}>
          {homeTeam?.teamLogo ? (
            <CardMedia className={classes.cardMedia} image={homeTeam?.teamLogo} />
          ) : (
            <DefaultOrgIcon className={classes.cardMedia} />
          )}
          <Typography variant='h5'>{homeTeam?.name}</Typography>
        </Container>
        <Box className={classes.scoreBox}>
          <Typography variant='h5'>
            {gameStats.status === STATS_GAME_STATUS.COMPLETED
              ? translate('ra.text.final')
              : translate('ra.text.score')}
          </Typography>
          <Typography variant='h5'>
            {homeTeam?.score} : {awayTeam?.score}
          </Typography>
          {gameStats?.gameTime && gameStats.status !== STATS_GAME_STATUS.COMPLETED && (
            <Box position='relative' top='25px'>
              {gameStats?.gameTime?.period <= 4 ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography>
                    {translate('ra.text.quarter')}
                    {`${gameStats?.gameTime?.period}`}
                  </Typography>
                  <Typography style={{ color: COLORS.grey, marginLeft: '8px' }}>
                    {` ${gameStats?.gameTime?.clock?.split(':').slice(1).join(':')}`}
                  </Typography>
                </div>
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography>{`OT${gameStats?.gameTime?.period - 3}`}</Typography>
                  <Typography style={{ color: COLORS.grey, marginLeft: '8px' }}>
                    {`${gameStats?.gameTime?.clock?.split(':').slice(1).join(':')}`}
                  </Typography>
                </div>
              )}
            </Box>
          )}
        </Box>
        <Container className={classes.logoContainer}>
          {awayTeam?.teamLogo ? (
            <CardMedia className={classes.cardMedia} image={awayTeam?.teamLogo} />
          ) : (
            <DefaultOrgIcon className={classes.cardMedia} />
          )}
          <Typography variant='h5'>{awayTeam?.name}</Typography>
        </Container>
      </CardContent>
    </Card>
  )
}

GameStatsInfo.propTypes = {
  gameStats: PropTypes.shape({
    status: PropTypes.string,
    teams: PropTypes.arrayOf(
      PropTypes.shape({
        score: PropTypes.number,
      }),
    ),
    gameTime: PropTypes.shape({
      period: PropTypes.number,
      clock: PropTypes.string,
    }),
  }),
  homeTeam: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    teamLogo: PropTypes.string,
  }),
  awayTeam: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    teamLogo: PropTypes.string,
  }),
  eventId: PropTypes.string,
}

export default GameStatsInfo
