import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import useOrgSettings from '../../../hooks/useOrgSettings'
import OrgSettingsForm from '../OrgSettingsForm/OrgSettingsForm'
import CardWrapperMoreIcon from '../../../components/layout/CardWrapperMoreIcon/CardWrapperMoreIcon'
import TextButton from '../../../components/ui/TextButton/TextButton'
import LoaderDots from '../../../components/loaders/LoaderDots/LoaderDots'
import PassOwnershipIcon from '../../../assets/icons/PassOwnershipIcon'
import COLORS from '../../../utils/constants/colors'
import useQueryParams from '../../../hooks/useQueryParams'
import deleteInvitation from '../../../Apis/invitations/deleteInvitation'
import QueryKeys from '../../../utils/constants/queryKeys'
import getOrgPendingInvitations from '../../../Apis/invitations/getOrgPendingInvitations'
import orgSettingPageStyles from './orgSettingsPage.styles'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import FansConfiguration from '../FansConfiguration/FansConfiguration'
import ContainerPadding from '../../../components/containerPadding/containerPadding'

const OrgSettingsPage = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isPassOwnershipModalOpen, setIsPassOwnershipModalOpen] = useState(false)
  const [ownershipRequestSent, setOwnershipRequestSent] = useState()
  const queryParams = useQueryParams()
  const { org: orgId } = queryParams
  const queryClient = useQueryClient()
  const translate = useTranslate()
  const { singleOrg, countries } = useOrgSettings()
  const useStyles = makeStyles(orgSettingPageStyles)
  const classes = useStyles()

  const getInvitationsQuery = useQuery(
    [QueryKeys.GET_ORG_PENDING_INVITATIONS],
    () => getOrgPendingInvitations({ orgId }),
    {
      onSuccess: (res) => {
        const ownerInvitation = res.find((inv) => inv.isOwner)

        if (!ownerInvitation) return setOwnershipRequestSent(undefined)

        return setOwnershipRequestSent({
          ...ownerInvitation,
          name: ownerInvitation.firstName
            ? ownerInvitation.firstName.concat(' ', ownerInvitation.lastName)
            : '',
        })
      },
    },
  )

  const deleteInvitationMutation = useMutation(
    [QueryKeys.DELETE_INVITATION],
    (data) => deleteInvitation(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.GET_ORG_PENDING_INVITATIONS).then(() => {})
        setOwnershipRequestSent(null)
      },
    },
  )

  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false)
  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true)

  const handleCloseOwnershipModal = () => setIsPassOwnershipModalOpen(false)
  const handleOpenOwnershipModal = () => {
    ownershipRequestSent
      ? deleteInvitationMutation.mutate({ invitationId: ownershipRequestSent.id, orgId })
      : setIsPassOwnershipModalOpen(true)
  }

  return (
    <CardWrapperMoreIcon>
      <Card className={classes.card}>
        <CardHeader
          title={
            <Typography className={classes.cardTitle} variant='h5'>
              {translate('ra.page.orgSettings')}
            </Typography>
          }
          subheader={
            <Typography className={classes.cardSubtitle}>
              Manage your Org information logo, name and address
            </Typography>
          }
        />
        <CardContent>
          <Divider variant='middle' className={classes.divider} />
          {singleOrg && countries ? (
            <OrgSettingsForm
              isDeleteModalOpen={isDeleteModalOpen}
              handleCloseDeleteModal={handleCloseDeleteModal}
              isPassOwnershipModalOpen={isPassOwnershipModalOpen}
              handleCloseOwnershipModal={handleCloseOwnershipModal}
            />
          ) : (
            <Grid container justifyContent='center'>
              <LoaderDots className={orgSettingPageStyles.loader} />
            </Grid>
          )}
          {singleOrg?.externalId && (
            <Container className={classes.containerCloseOrg}>
              <span style={{ paddingLeft: 15 }}>External Id: {singleOrg?.externalId}</span>
            </Container>
          )}
          <Divider variant='middle' className={classes.divider} />
          <div
            style={{ paddingLeft: '3%', paddingRight: '3%', paddingTop: '2%', paddingBottom: '2%' }}
          >
            <FansConfiguration />
          </div>

          <Divider variant='middle' className={classes.divider} />
          <Container className={classes.containerCloseOrg}>
            <Grid container>
              <Grid item xs={9}>
                <Typography variant='h6'>Pass Ownership</Typography>
                {!ownershipRequestSent ? (
                  <Typography variant='subtitle1' className={classes.infoText}>
                    {translate('ra.text.passOwnershipInfo')}
                  </Typography>
                ) : (
                  <div className={classes.info}>
                    <PassOwnershipIcon />
                    <Typography variant='subtitle1' color='textSecondary'>
                      {translate('ra.text.ownershipRequested')}
                      <span style={{ color: COLORS.purple }}>
                        {ownershipRequestSent?.name || ownershipRequestSent?.receiverEmail}
                      </span>
                      {translate('ra.text.toBecomeOwner')}
                    </Typography>
                  </div>
                )}
              </Grid>
              <Grid item container xs={3} justifyContent='flex-end' alignItems='center'>
                {getInvitationsQuery.isLoading || deleteInvitationMutation.isLoading ? (
                  <LoaderDots className={classes.loaderIcon} />
                ) : (
                  <ButtonWithLoader
                    classes={{ root: classes.deleteButton }}
                    label={translate(
                      !ownershipRequestSent
                        ? 'ra.buttons.passOwnership'
                        : 'ra.buttons.cancelRequest',
                    )}
                    onClick={handleOpenOwnershipModal}
                  />
                )}
              </Grid>
            </Grid>
          </Container>

          <Divider variant='middle' className={classes.divider} />
          <Container className={classes.containerCloseOrg}>
            <Grid container>
              <Grid item xs={9}>
                <Typography variant='h6'>Close organisation</Typography>
                <Typography variant='subtitle1' className={classes.infoText}>
                  {translate('ra.text.deleteOrgInfo')}
                </Typography>
              </Grid>
              <Grid item container xs={3} justifyContent='flex-end' alignItems='center'>
                <TextButton
                  onClick={handleOpenDeleteModal}
                  label={translate('ra.buttons.delete')}
                  classes={{ root: classes.deleteButton }}
                />
              </Grid>
            </Grid>
          </Container>
        </CardContent>
      </Card>
    </CardWrapperMoreIcon>
  )
}

export default OrgSettingsPage
