import React from 'react'
import { Field, Form, Formik } from 'formik'
import { Grid } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import MyTextField from '../../../components/form/fields/MyTextField'
import MyAutocomplete from '../../../components/form/fields/MyAutocomplete'
import { countryCodeToFlag } from '../../../utils/helpers/countryCodeToFlag'
import DeleteConfirmationModal from '../../../components/ui/DeleteConfirmationModal/DeleteConfirmationModal'
import useQueryParams from '../../../hooks/useQueryParams'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import useOrgSettings from '../../../hooks/useOrgSettings'
import ImageCropWithEdit from '../../../components/ui/ImageCropComponents/ImageCropWithEdit/ImageCropWithEdit'
import ContainerPadding from '../../../components/containerPadding/containerPadding'
import PassOwnershipModal from '../PassOwnershipModal/PassOwnershipModal'
import emptyOrg from '../../../assets/images/org_empty.svg'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import ORGANISATION_TYPES from '../../../utils/constants/OrganisationTypes'
import FansConfiguration from '../FansConfiguration/FansConfiguration'

const OrgSettingsForm = (props) => {
  const {
    isDeleteModalOpen,
    handleCloseDeleteModal,
    isPassOwnershipModalOpen,
    handleCloseOwnershipModal,
  } = props
  const { singleOrg, countries, updateOrg, deleteOrg } = useOrgSettings()
  const { name, country, city, logoUrl, type } = singleOrg
  const translate = useTranslate()
  const queryParams = useQueryParams()
  const { org: orgId } = queryParams

  const onSubmit = (data) => {
    const { city, name, country, logo } = data
    updateOrg.mutate({
      name,
      city,
      logo,
      countryId: country?.id,
      orgId,
    })
  }

  return (
    <ContainerPadding padding='4% 4% 4% 4%'>
      <Formik
        initialValues={{
          name,
          country,
          city,
          logo: logoUrl,
        }}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ isValid, dirty, values, setFieldValue }) => (
          <>
            <Form style={{ width: '100%' }}>
              <Grid container spacing={2}>
                <ConditionalRender renderValue={singleOrg}>
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent='center'
                    style={{ marginTop: 10, marginBottom: 20 }}
                  >
                    <ImageCropWithEdit
                      defaultImageSrc={values.logo}
                      setCroppedImage={(croppedImage) => setFieldValue('logo', croppedImage)}
                      editPreviewWidth={110}
                      emptyPreviewIcon={emptyOrg}
                    />
                  </Grid>
                </ConditionalRender>
                <Grid item xs={12}>
                  <MyTextField label={translate('ra.formFields.name')} name='name' required />
                </Grid>
                <ConditionalRender renderValue={type === ORGANISATION_TYPES.ORG_CLUB}>
                  <Grid item xs={6}>
                    <Field
                      name='country'
                      label='Country'
                      variant='filled'
                      required
                      options={countries || []}
                      component={MyAutocomplete}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option) => option.id === singleOrg?.country?.id}
                      defaultValue={singleOrg.country}
                      renderOption={(option) => (
                        <>
                          <span style={{ marginRight: 10 }}>{countryCodeToFlag(option.code)}</span>
                          {option.name} ({option.code})
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MyTextField
                      label={translate('ra.formFields.city')}
                      name='city'
                      variant='filled'
                      required
                    />
                  </Grid>
                </ConditionalRender>
                <Grid item container xs={12} justifyContent='flex-end'>
                  <ButtonWithLoader
                    label={translate('ra.buttons.update')}
                    disabled={!(isValid && dirty)}
                    loading={updateOrg.isLoading}
                  />
                </Grid>
              </Grid>
            </Form>
            <DeleteConfirmationModal
              isModalOpen={isDeleteModalOpen}
              onClose={handleCloseDeleteModal}
              title={translate('ra.text.deleteOrg')}
              subtitle={translate('ra.text.deleteOrgName')}
              comparisonValue={values.name}
              buttonLabel={translate('ra.action.delete')}
              textFieldLabel={translate('ra.formFields.name')}
              onSubmit={() => deleteOrg.mutate({ orgId })}
              isLoading={deleteOrg.isLoading}
            />
            <PassOwnershipModal
              open={isPassOwnershipModalOpen}
              onClose={handleCloseOwnershipModal}
              orgId={orgId}
            />
          </>
        )}
      </Formik>
    </ContainerPadding>
  )
}

OrgSettingsForm.propTypes = {
  isDeleteModalOpen: PropTypes.bool,
  handleCloseDeleteModal: PropTypes.func,
  isPassOwnershipModalOpen: PropTypes.bool,
  handleCloseOwnershipModal: PropTypes.func,
}

export default OrgSettingsForm
