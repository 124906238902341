export const redirectToApp = ({ scheme, path, params = {}, fallbackUrl, timeout = 5000 }) => {
  const deepLink = 'mismatchfans://confirm'

  window.location.href = deepLink

  const iframe = document.createElement('iframe')
  iframe.style.display = 'none'
  iframe.src = deepLink
  document.body.appendChild(iframe)
}
