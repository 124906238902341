import COLORS from '../../../utils/constants/colors'

const fansConfigurationStyles = {
  toggle: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: COLORS.orange,
      '&:hover': {
        backgroundColor: `rgba(${COLORS.orange.r},${COLORS.orange.g},${COLORS.orange.b}, 0.5)`,
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: COLORS.orange,
    },
    '& .Mui-disabled': {
      opacity: 0.5,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: 20,
  },
}

export default fansConfigurationStyles
