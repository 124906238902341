import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useMutation } from 'react-query'
import { AlertTitle } from '@material-ui/lab'
import { useFormikContext } from 'formik'
import useHistoryPush from '../../hooks/useHistoryPush'
import confirmEmail from '../../Apis/user/confirmEmail'
import getQueryParameterByName from '../../utils/helpers/getQueryParameterByName'
import MySnackbar from '../MySnackbar/MySnackbar'
import localStorageIds from '../../utils/constants/localStorageIds'
import { redirectToApp } from '../../utils/helpers/redirectToApp'

const ConfirmEmail = () => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [counter, setCounter] = useState()
  const translate = useTranslate()
  const { redirect } = useHistoryPush()
  const email = getQueryParameterByName('email')
  const emailToken = getQueryParameterByName('emailToken')
  const redirectUrl = getQueryParameterByName('redirect')
  const { setFieldValue } = useFormikContext()

  // Remove token in case user is logged in with another account.
  localStorage.removeItem(localStorageIds.TOKEN)

  const confirmUserEmail = useMutation((data) => confirmEmail(data), {
    onSuccess: () => {
      setIsSnackbarOpen(true)
      if (!!redirectUrl) redirectToApp('mismatchfans', 'confirm')
    },
    onError: () => {
      setCounter(6)
      setIsSnackbarOpen(true)
    },
  })

  useEffect(() => {
    if (email && emailToken)
      confirmUserEmail.mutate({
        email,
        emailToken,
      })
    setFieldValue('email', email)
  }, [])

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)

    if (counter === 0) {
      redirect('/send-confirmation-email', [{ initialEmail: email }])
    }

    return () => clearInterval(timer)
  }, [counter])

  const onCloseSnackbar = () => setIsSnackbarOpen(false)

  return (
    <MySnackbar
      isOpen={isSnackbarOpen}
      onClose={onCloseSnackbar}
      isSuccess={confirmUserEmail.isSuccess}
      successMessage={translate('ra.successMessages.confirmEmailSuccess')}
      errorMessage={
        <>
          <AlertTitle>{translate('ra.errorMessages.confirmEmailError')}</AlertTitle>
          {`${translate('ra.errorMessages.confirmEmailErrorRedirect')} `}
          <b>{counter}</b>
        </>
      }
    />
  )
}

export default ConfirmEmail
