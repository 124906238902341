import localStorageIds from './localStorageIds'
import { CLIENT_TYPE_HEADER } from './ClientTypeHeader'

const generalHeader = (token) => ({
  accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Client-Type': CLIENT_TYPE_HEADER,
  'Club-Id': JSON.parse(localStorage.getItem(localStorageIds.SELECTED_ORG))?.id,
  ...(token && { Authorization: `Bearer ${token}` }),
})

export default generalHeader
