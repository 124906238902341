import axios from 'axios'
import { BASE_URL } from '../../utils/constants/url'
import generalHeader from '../../utils/constants/generalHeader'
import { CLIENT_TYPE_HEADER } from '../../utils/constants/ClientTypeHeader'

const resendConfirmationEmail = (data) => {
  const url = `${BASE_URL}/auth/send-confirmation-email`
  const clientData = { ...data, platform: CLIENT_TYPE_HEADER }

  return axios.post(url, clientData, {
    headers: generalHeader(),
  })
}

export default resendConfirmationEmail
