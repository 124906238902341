import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import useHistoryPush from '../../hooks/useHistoryPush'
import playerPerGameTableStyles from './playerPerGameTable.styles'
import formatFullName from '../../utils/helpers/practicePlayersMapper/formatFullName'

const PlayersPerGameTable = ({ players, teamOnlyStats, totalStats }) => {
  const useStyles = makeStyles(playerPerGameTableStyles)
  const classes = useStyles()
  const translate = useTranslate()
  const { redirect } = useHistoryPush()

  if (!players || players.length === 0) {
    return null
  }

  const fmtPerc = (value, total) => (total == 0 ? '-' : `${Math.round((value / total) * 100)}%`)
  const fmtRatio = (value, total) => (total == 0 ? '-' : `${value}/${total}`)

  // Map players to playerObjects
  const playerObjects = players.map((player) => {
    return {
      id: player.playerId,
      gameTeamPlayerId: player.gameTeamPlayerId,
      fullName: player.fullName,
      jerseyNumber: player.jerseyNumber,
      minutes: player.stats.minutes.slice(3),
      points: player.stats.points,
      twoPointer: fmtRatio(player.stats.made2Pointers, player.stats.attempted2Pointers),
      avgTwoPoints: fmtPerc(player.stats.made2Pointers, player.stats.attempted2Pointers),
      threePointer: fmtRatio(player.stats.made3Pointers, player.stats.attempted3Pointers),
      avgThreePoints: fmtPerc(player.stats.made3Pointers, player.stats.attempted3Pointers),
      freeThrows: fmtRatio(player.stats.made1Pointers, player.stats.attempted1Pointers),
      avgFreeThrows: fmtPerc(player.stats.made1Pointers, player.stats.attempted1Pointers),
      offensiveRebounds: player.stats.offensiveRebounds,
      defensiveRebounds: player.stats.defensiveRebounds,
      totalRebounds: player.stats.rebounds,
      assists: player.stats.assists,
      steals: player.stats.steals,
      turnOvers: player.stats.turnovers,
      blocksAgainst: player.stats.blocksAgainst,
      foulsCommited: player.stats.fouls,
      foulsDrawn: player.stats.foulsDrawn,
      pir: player.stats.pir,
    }
  })

  // Separate players into those who played and those who did not
  const playedPlayers = playerObjects
    .filter((player) => player.minutes !== '00:00' && player.minutes !== null)
    .sort((a, b) => a.jerseyNumber - b.jerseyNumber)

  const didNotPlayPlayers = playerObjects
    .filter((player) => player.minutes === '00:00' || player.minutes === null)
    .sort((a, b) => a.jerseyNumber - b.jerseyNumber)

  const handleRowClick = (playerId) => {
    redirect(`/stats/player/${playerId}`)
  }

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow className={classes.tableHeader}>
          <TableCell className={classes.playerCell}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>&nbsp;</span>
              <span style={{ marginLeft: 5 }}>{translate('ra.text.players')}</span>
            </div>
          </TableCell>
          <TableCell colSpan={2} className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>&nbsp;</span>
              <div className={classes.div}>
                <span>MIN</span>
                <span>PTS</span>
              </div>
            </div>
          </TableCell>
          <TableCell colSpan={2} className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>2FG</span>
              <div className={classes.div}>
                <span>M/A</span>
                <span>%</span>
              </div>
            </div>
          </TableCell>
          <TableCell colSpan={2} className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>3FG</span>
              <div className={classes.div}>
                <span>M/A</span>
                <span>%</span>
              </div>
            </div>
          </TableCell>
          <TableCell colSpan={2} className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>FT</span>
              <div className={classes.div}>
                <span>M/A</span>
                <span>%</span>
              </div>
            </div>
          </TableCell>
          <TableCell colSpan={3} className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>REBOUNDS</span>
              <div className={classes.div}>
                <span>OR</span>
                <span>DR</span>
                <span>TR</span>
              </div>
            </div>
          </TableCell>
          <TableCell colSpan={3} className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>&nbsp;</span>
              <div className={classes.div}>
                <span>AST</span>
                <span>STL</span>
                <span>TO</span>
              </div>
            </div>
          </TableCell>
          <TableCell className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>&nbsp;</span>
              <div className={classes.spanDiv}>
                <span>BLKA</span>
              </div>
            </div>
          </TableCell>
          <TableCell colSpan={2} className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>FOULS</span>
              <div className={classes.div}>
                <span>FC</span>
                <span>FD</span>
              </div>
            </div>
          </TableCell>
          <TableCell className={classes.tableCellBorder}>
            <div className={classes.containerDiv}>
              <span className={classes.span}>&nbsp;</span>
              <div className={classes.spanDiv}>
                <span>PIR</span>
              </div>
            </div>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {/* Render players who played */}
        {playedPlayers.map((player) => (
          <TableRow
            key={player.id || player.gameTeamPlayerId}
            onClick={() => (player?.id ? handleRowClick(player.id) : {})}
            className={player?.id ? classes.hoverRow : ''}
          >
            <TableCell>
              <div className={classes.ellipsisDiv}>
                <span style={{ fontWeight: 'bold', marginLeft: 5 }}>{player.jerseyNumber}</span>
                <span style={{ marginLeft: 10 }}>{formatFullName(player.fullName)}</span>
              </div>
            </TableCell>
            <TableCell style={{ paddingLeft: '1%' }} className={classes.tableCell} colSpan={2}>
              <div className={classes.containerDiv}>
                <div className={classes.minutesDiv}>
                  <span style={{ paddingLeft: { xs: '1%', lg: '5%' } }}>{player.minutes}</span>
                  <span
                    className={player.points < 10 ? classes.spaceRight : classes.spaceRightSmaller}
                  >
                    {player.points}
                  </span>
                </div>
              </div>
            </TableCell>

            <TableCell colSpan={2} className={classes.tableCell}>
              <div className={classes.containerDiv}>
                <div className={classes.div}>
                  <span>{player.twoPointer}</span>
                  <span>{player.avgTwoPoints}</span>
                </div>
              </div>
            </TableCell>

            <TableCell colSpan={2} className={classes.tableCell}>
              <div className={classes.containerDiv}>
                <div className={classes.div}>
                  <span>{player.threePointer}</span>
                  <span>{player.avgThreePoints}</span>
                </div>
              </div>
            </TableCell>

            <TableCell colSpan={2} className={classes.tableCell}>
              <div className={classes.containerDiv}>
                <div className={classes.div}>
                  <span>{player.freeThrows}</span>
                  <span>{player.avgFreeThrows}</span>
                </div>
              </div>
            </TableCell>

            <TableCell colSpan={3} className={classes.tableCell}>
              <div className={classes.containerDiv}>
                <div className={classes.div}>
                  <span className={player.totalRebounds < 10 ? classes.spaceLeft5 : ''}>
                    {player.offensiveRebounds}
                  </span>
                  <span>{player.defensiveRebounds}</span>
                  <span className={player.totalRebounds < 10 ? classes.spaceRight5 : ''}>
                    {player.totalRebounds}
                  </span>
                </div>
              </div>
            </TableCell>

            <TableCell colSpan={3} className={classes.tableCell}>
              <div className={classes.containerDiv}>
                <div className={classes.div}>
                  <span className={player.assists < 10 ? classes.spaceLeft : ''}>
                    {player.assists}
                  </span>
                  <span>{player.steals}</span>
                  <span className={player.turnOvers < 10 ? classes.spaceRight5 : ''}>
                    {player.turnOvers}
                  </span>
                </div>
              </div>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <div className={classes.spanDiv}>
                <span>{player.blocksAgainst}</span>
              </div>
            </TableCell>
            <TableCell colSpan={2} className={classes.tableCell}>
              <div className={classes.containerDiv}>
                <div className={classes.div}>
                  <span className={player.foulsCommited < 10 ? classes.spaceLeft5 : ''}>
                    {player.foulsCommited}
                  </span>
                  <span className={player.foulsDrawn < 10 ? classes.spaceRight5 : ''}>
                    {player.foulsDrawn}
                  </span>
                </div>
              </div>
            </TableCell>

            <TableCell className={classes.tableCell}>
              <div className={classes.spanDiv}>
                <span>{player.pir}</span>
              </div>
            </TableCell>
          </TableRow>
        ))}
        {/* Render players who did not play */}
        {didNotPlayPlayers.map((player) => (
          <TableRow
            key={player.id || player.gameTeamPlayerId}
            onClick={() => (player?.id ? handleRowClick(player.id) : {})}
            className={player?.id ? classes.hoverRow : ''}
          >
            <TableCell>
              <div className={classes.ellipsisDiv}>
                <span style={{ fontWeight: 'bold', marginLeft: 5 }}>{player.jerseyNumber}</span>
                <span style={{ marginLeft: 10 }}>{formatFullName(player.fullName)}</span>
              </div>
            </TableCell>
            <TableCell colSpan={18} className={classes.tableCell}>
              {translate('ra.text.didNotPlay')}
            </TableCell>
          </TableRow>
        ))}
        {teamOnlyStats && (
          <TableRow key={playedPlayers[0].gameTeamPlayerId}>
            <TableCell>
              <div className={classes.ellipsisDiv}>
                <span style={{ marginLeft: 10 }}>Team/Bench</span>
              </div>
            </TableCell>

            <TableCell colSpan={8} className={classes.tableCell}></TableCell>

            <TableCell colSpan={3} className={classes.tableCell}>
              <div className={classes.containerDiv}>
                <div className={classes.div}>
                  <span className={teamOnlyStats.rebounds < 10 ? classes.spaceLeft5 : ''}>
                    {teamOnlyStats.offensiveRebounds}
                  </span>
                  <span>{teamOnlyStats.defensiveRebounds}</span>
                  <span className={teamOnlyStats.rebounds < 10 ? classes.spaceRight5 : ''}>
                    {teamOnlyStats.rebounds}
                  </span>
                </div>
              </div>
            </TableCell>

            <TableCell colSpan={3} className={classes.tableCell}>
              <div className={classes.containerDiv}>
                <div className={classes.div}>
                  <span className={teamOnlyStats.assists < 10 ? classes.spaceLeft : ''}>
                    {teamOnlyStats.assists}
                  </span>
                  <span>{teamOnlyStats.steals}</span>
                  <span className={teamOnlyStats.turnovers < 10 ? classes.spaceRight5 : ''}>
                    {teamOnlyStats.turnovers}
                  </span>
                </div>
              </div>
            </TableCell>

            <TableCell className={classes.tableCell}>
              <div className={classes.spanDiv}>
                <span>{teamOnlyStats.blocksAgainst}</span>
              </div>
            </TableCell>

            <TableCell colSpan={2} className={classes.tableCell}>
              <div className={classes.containerDiv}>
                <div className={classes.div}>
                  <span className={teamOnlyStats.fouls < 10 ? classes.spaceLeft5 : ''}>
                    {teamOnlyStats.fouls}
                  </span>
                  <span className={teamOnlyStats.foulsDrawn < 10 ? classes.spaceRight5 : ''}>
                    {teamOnlyStats.foulsDrawn}
                  </span>
                </div>
              </div>
            </TableCell>

            <TableCell className={classes.tableCell}>
              <div className={classes.spanDiv}>
                <span>{teamOnlyStats.pir}</span>
              </div>
            </TableCell>
          </TableRow>
        )}
        {totalStats && (
          <TableRow key='total-stats'>
            <TableCell>
              <div className={classes.ellipsisDiv}>
                <span style={{ marginLeft: 10 }}>
                  <b>Total</b>
                </span>
              </div>
            </TableCell>

            <TableCell style={{ paddingLeft: '1%' }} className={classes.tableCell} colSpan={2}>
              <div className={classes.containerDiv}>
                <div className={classes.minutesDiv}>
                  <span style={{ paddingLeft: { xs: '1%', lg: '5%' } }}></span>
                  <span
                    className={
                      totalStats.points < 10 ? classes.spaceRight : classes.spaceRightSmaller
                    }
                  >
                    {totalStats.points}
                  </span>
                </div>
              </div>
            </TableCell>

            <TableCell colSpan={2} className={classes.tableCell}>
              <div className={classes.containerDiv}>
                <div className={classes.div}>
                  <span>{fmtRatio(totalStats.made2Pointers, totalStats.attempted2Pointers)}</span>
                  <span>{fmtPerc(totalStats.made2Pointers, totalStats.attempted2Pointers)}</span>
                </div>
              </div>
            </TableCell>

            <TableCell colSpan={2} className={classes.tableCell}>
              <div className={classes.containerDiv}>
                <div className={classes.div}>
                  <span>{fmtRatio(totalStats.made3Pointers, totalStats.attempted3Pointers)}</span>
                  <span>{fmtPerc(totalStats.made3Pointers, totalStats.attempted3Pointers)}</span>
                </div>
              </div>
            </TableCell>

            <TableCell colSpan={2} className={classes.tableCell}>
              <div className={classes.containerDiv}>
                <div className={classes.div}>
                  <span>{fmtRatio(totalStats.made1Pointers, totalStats.attempted1Pointers)}</span>
                  <span>{fmtPerc(totalStats.made1Pointers, totalStats.attempted1Pointers)}</span>
                </div>
              </div>
            </TableCell>

            <TableCell colSpan={3} className={classes.tableCell}>
              <div className={classes.containerDiv}>
                <div className={classes.div}>
                  <span className={totalStats.rebounds < 10 ? classes.spaceLeft5 : ''}>
                    {totalStats.offensiveRebounds}
                  </span>
                  <span>{totalStats.defensiveRebounds}</span>
                  <span className={totalStats.rebounds < 10 ? classes.spaceRight5 : ''}>
                    {totalStats.rebounds}
                  </span>
                </div>
              </div>
            </TableCell>

            <TableCell colSpan={3} className={classes.tableCell}>
              <div className={classes.containerDiv}>
                <div className={classes.div}>
                  <span className={totalStats.assists < 10 ? classes.spaceLeft : ''}>
                    {totalStats.assists}
                  </span>
                  <span>{totalStats.steals}</span>
                  <span className={totalStats.turnovers < 10 ? classes.spaceRight5 : ''}>
                    {totalStats.turnovers}
                  </span>
                </div>
              </div>
            </TableCell>

            <TableCell className={classes.tableCell}>
              <div className={classes.spanDiv}>
                <span>{totalStats.blocksAgainst}</span>
              </div>
            </TableCell>

            <TableCell colSpan={2} className={classes.tableCell}>
              <div className={classes.containerDiv}>
                <div className={classes.div}>
                  <span className={totalStats.fouls < 10 ? classes.spaceLeft5 : ''}>
                    {totalStats.fouls}
                  </span>
                  <span className={totalStats.foulsDrawn < 10 ? classes.spaceRight5 : ''}>
                    {totalStats.foulsDrawn}
                  </span>
                </div>
              </div>
            </TableCell>

            <TableCell className={classes.tableCell}>
              <div className={classes.spanDiv}>
                <span>{totalStats.pir}</span>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

// Define PropTypes for the component
PlayersPerGameTable.propTypes = {
  players: PropTypes.arrayOf(
    PropTypes.shape({
      playerId: PropTypes.string,
      gameTeamPlayerId: PropTypes.string,
      fullName: PropTypes.string,
      jerseyNumber: PropTypes.number,
      opponentName: PropTypes.string,
      gamesPlayed: PropTypes.number,
      gamesStarted: PropTypes.number,
      minutes: PropTypes.string,
      gameStats: PropTypes.shape({
        points: PropTypes.number,
        avgPoints: PropTypes.number,
        twoPointer: PropTypes.number,
        avgTwoPoints: PropTypes.number,
        totalTwoPointsAttempts: PropTypes.number,
        threePointer: PropTypes.number,
        avgThreePoints: PropTypes.number,
        totalThreePointsAttempts: PropTypes.number,
        freeThrows: PropTypes.number,
        avgFreeThrows: PropTypes.number,
        totalFreeThrowsAttempts: PropTypes.number,
        offensiveRebounds: PropTypes.number,
        avgOffensiveRebounds: PropTypes.number,
        defensiveRebounds: PropTypes.number,
        avgDefensiveRebounds: PropTypes.number,
        totalRebounds: PropTypes.number,
        avgRebounds: PropTypes.number,
        assists: PropTypes.number,
        avgAssists: PropTypes.number,
        steals: PropTypes.number,
        avgSteals: PropTypes.number,
        turnOvers: PropTypes.number,
        avgTurnOvers: PropTypes.number,
        blocksAgainst: PropTypes.number,
        avgBlocksAgainst: PropTypes.number,
        foulsCommited: PropTypes.number,
        avgFoulsCommited: PropTypes.number,
        foulsDrawn: PropTypes.number,
        avgFoulsDrawn: PropTypes.number,
        pir: PropTypes.number,
        avgPIR: PropTypes.number,
      }),
    }),
  ),
}

export default PlayersPerGameTable
