import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useLocation } from 'react-router-dom'
import { Link, makeStyles, Typography } from '@material-ui/core'
import { useMutation } from 'react-query'
import NoLayoutContainer from '../../components/layout/NoLayoutContainer/NoLayoutContainer'
import ButtonWithLoader from '../../components/ui/ButtonWithLoader/ButtonWithLoader'
import resendConfirmationEmail from '../../Apis/user/resendConfirmationEmail'
import sendConfirmationEmailPageStyles from './sendConfirmationEmailPage.styles'
import MySnackbar from '../../components/MySnackbar/MySnackbar'

const SendConfirmationEmailPage = () => {
  const { search } = useLocation()
  const initialEmail = search.replace('?initialEmail=', '')
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const translate = useTranslate()
  const useStyles = makeStyles(sendConfirmationEmailPageStyles)
  const classes = useStyles()

  const resendConfirmEmail = useMutation((data) => resendConfirmationEmail(data), {
    onSuccess: () => setIsSnackbarOpen(true),
    onError: () => setIsSnackbarOpen(true),
  })
  const clickHandler = () => {
    resendConfirmEmail.mutate({ email: { email: initialEmail, name: '' } })
  }

  const onCloseSnackbar = () => setIsSnackbarOpen(false)

  return (
    <NoLayoutContainer>
      <div className={classes.wrapper}>
        <Typography variant='h5'>{translate('ra.text.emailSent')}</Typography>
        <ButtonWithLoader
          label={translate('ra.buttons.resendMail')}
          type='button'
          disabled={false}
          onClick={() => {
            clickHandler()
          }}
          loading={false}
        />
        <Link href='/login' variant='body2' className={classes.link}>
          {translate('ra.gridLinks.login')}
        </Link>
      </div>

      <MySnackbar
        isOpen={isSnackbarOpen}
        onClose={onCloseSnackbar}
        isSuccess={resendConfirmEmail.isSuccess}
        successMessage={translate('ra.successMessages.emailResent')}
        errorMessage={translate('ra.errorMessages.emailResentError')}
      />
    </NoLayoutContainer>
  )
}

export default SendConfirmationEmailPage
