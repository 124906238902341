import TERMS_AND_PRIVACY_URLS from '../utils/constants/termsAndPrivacyUrls'
import VIDEO_SIZE from '../utils/constants/uploadFileSize'

const en = {
  ra: {
    notification: {
      genericError: 'Something went wrong',
      loginError: 'Something went wrong, please try again',
      deleteSuccess: 'Deleted Successfully',
      deleteError: 'An error occurred during deletion',
      created: 'Created Successfully',
      updated: 'Updated Successfully',
      updatedError: 'An error occurred during updating',
      item_doesnt_exist: "Item doesn't exists",
      noNotifications: 'No Notifications.',
      invitation: 'Invitation',
      billing: 'Billing',
      serverError: 'Unable to load notifications.',
    },
    invitation: {
      pending: 'invited you to join organisation',
    },
    billing: {
      warning: 'Your billing period is ending in: ',
      ended: 'Your billing period has expired.',
    },
    validationErrors: {
      inValidEmail: 'Please insert a valid e-mail',
      password: 'Must Contain 6 Characters, One Uppercase, One Lowercase, and One Number.',
      confirmPassword: 'Passwords must match',
      terms: 'Accept Terms & Conditions is required',
      requiredField: 'Required field',
      fromDate: "The start date of a livestream event can't be in the past",
      startTime: "The start time of a livestream event can't be in the past",
      endTime: "The end time of a livestream event can't be lest than 30 minutes.",
      courtId: 'When creating livestream event, court is required.',
      threeLetter: 'The name identifier must be 3 letters.',
    },
    message: {
      invalid_form: 'INVALID FORM',
      not_found: 'The page you requested does not exist',
    },
    auth: {
      logout: 'Log Out',
      auth_check_error: 'Authorization Error',
    },
    page: {
      dashboard: 'Dashboard',
      login: 'Log In',
      signUp: 'Sign Up',
      terms: 'Terms and Conditions',
      forgotPassword: 'Forgot Password',
      resetPassword: 'Reset Password',
      profile: 'Profile',
      orgSettings: "Organisation's Settings",
      security: 'Security',
      preferences: 'Preferences',
      addOrg: 'Add Organisation',
      edit: 'EDIT',
      list: 'LIST',
      create: 'CREATE',
      not_found: 'Page not found',
      show: 'SHOW',
      subscription: 'SUBSCRIPTION',
      payments: 'PAYMENTS',
      billingDetails: 'BILLING DETAILS',
    },
    text: {
      team: 'Team ',
      teamsOfThree: 'Teams of Three',
      teamsOfFour: 'Teams of Four',
      teamsOfFive: 'Teams of Five',
      players: 'PLAYERS',
      selectedPlayers: 'Players',
      playersInPractice: 'Players for practice.',
      teamsInPractice: 'Teams for practice.',
      downloadRecording: 'Download Recording',
      notMobileOptimised: 'The app is not yet optimised for Mobile.',
      notVerticalOptimised:
        'The app is not yet optimised for vertical use, please turn your device horizontally.',
      tryDesktop: 'Give it a try on a Desktop or Tablet!',
      emailConfirm: 'Please press the button to confirm your email',
      feelGame: 'Feel the game',
      createOrg: 'Create your first organisation',
      orgAvatar: 'Club Logo',
      teamAvatar: 'Team Logo',
      deleteEventTitle: 'Are you sure you want to delete this event?',
      deleteEventSubtitle: 'This action will delete the event"". Do you wish to proceed?',
      noPractices: 'No Practices found for the selected dates!',
      deletePractice: 'Are you sure you want to delete this practice?',
      deletePracticePlan: 'Are you sure you want to delete this practice plan?',
      description: 'Description',
      publish: 'Publish',
      tags: 'Tags',
      viewMore: 'VIEW MORE',
      deleteDrill: 'Are you sure you want to delete drill',
      removeDrill: 'Are you sure you want to remove drill',
      backToOrg: 'Back to Organisation',
      text: 'Text',
      drill: 'Drill',
      createNewDrill: 'Create New Drill',
      noTrainingModulesFound: 'No exercises found!',
      createNew: 'Create New',
      minutes: 'Minutes',
      activity: 'Activity',
      notes: 'Notes',
      min: 'min',
      practicePlans: 'Practice Plans',
      noPracticePlans: 'No practice plans found',
      addPracticePlan: 'Add Practice Plan',
      practicePlanTopic: 'Practice Plan Topic',
      tacticsGroups: 'TACTICS GROUPS',
      drillUpdateModeQuestionPP:
        'Would you like to change the drill only in the specific practice plan?',
      drillUpdateModeQuestionPlaybook:
        'Would you like to change the drill only in the specific playbook?',
      changeEmail: 'Change your e-mail',
      insertNewEmail: 'Insert the new email and your password',
      changePhoneNumber: 'Change your Phone number',
      insertNewPhoneNumber: 'Insert the new phone number and your password',
      disableAccount: 'Are you sure you want to close your account?',
      insertPassword: 'Insert your password to close your account',
      closeAccount: 'Close account',
      disableAccountDataLost: 'If you close your account, all your data will be lost.',
      deleteOrg: 'Are you sure you want to delete your organisation?',
      deleteOrgName: 'Please write the name of the organisation you wish to delete',
      deleteTeam: 'Are you sure you want to delete your team?',
      deleteTeamName: 'Please write the name of the team you wish to delete',
      pendingEmail: 'Pending email(s)',
      forgotPassword: 'Please enter your email to receive a reset password link',
      noCurrentTeams: 'There are no teams available',
      graphics: 'Graphics',
      courts: 'Courts',
      courtsList: 'List of your courts',
      orgUsersList: 'List of organisation users and pending invitations',
      invitationsList: 'List of your pending invitations',
      emptyCourts: 'No courts available',
      emailSent: 'A verification mail has been sent to your account!',
      deleteOrgInfo:
        "By closing the organisation you would delete all it's teams and it's members.",
      passOwnershipInfo:
        'By passing ownership of the organisation you would pass all rights of user editing and billing to another member of the organisation.',
      passOwnershipSuccess: 'Your request was sent. Now you should wait the user to accept it.',
      managePlayers: "Manage your team's members",
      stopLivestream: 'Are you sure you want to stop running livestream?',
      startLivestream: 'Are you sure you want to start the livestream?',
      createOrgAndTeam: 'What type is your organisation?',
      addTeamText: 'Add Team',
      recent: 'RECENT',
      teamSettings: "Team's Settings",
      manageOrgTeams: "Manage your organisation's teams, their information and categories",
      noRecentLivestream: 'There are no recent livestreams!',
      coachCorner: "COACH'S CORNER",
      inviteUsers: 'Invite Users',
      setRoles: 'Set Roles',
      deleteOrgUser: 'Are you sure you want to remove this user from the organisation?',
      deleteInvitationConfirmation: 'Are you sure you want to reject the invitation from ',
      passOwnership: 'Pass Ownership of the organisation',
      paymentSuccess: 'Payment success',
      changeCardSuccess: 'Card change success',
      paymentFail: 'Payment Failed',
      changeCardFail: 'Card change fail',
      userPricePerMonth: 'per user/month',
      addingNewUser: 'Adding new user?',
      addingNewUserText:
        'Any new user will be added as a one-time, prorated charge based on the remainder of your billing cycle.',
      removingUser: 'Removing user?',
      removingUserText:
        "When you remove a user, it's seat will remain open on your organisation until the next billing cycle. If you have an open seat when adding a new user, you won't be charged.",
      getInvoice: 'How do I get my invoice?',
      getInvoiceText:
        'If you have added all the details needed, you will get your invoice in 24 hours via email.',
      contactYou: 'How do I contact you?',
      contactYouText:
        'For any info regarding billing and subscription contact us at accounting@mismatch.gr',
      orgSubscription: 'Add-ons',
      activateOrgSubscription: 'Your Addons',
      faq: 'Frequently Asked Questions',
      subscriptionSummary: 'Billing Summary',
      orgSeats: 'Seats',
      reActivateOrgSubscription: 'Re-activate your Organisation’s subscription',
      trial: 'Trial',
      subscription: 'Subscription',
      monthly: 'Monthly',
      noTransactions: 'No payment transactions found!',
      addANote: 'Add a note',
      copied: 'Copied',
      orgMembers: 'ORGANISATION MEMBERS',
      uploadVideoInfo:
        'Videos should be short enough to fit in breaks and their file size should not exceed 20Mb.',
      uploadDrillVideoInfo: `Video should be short enough and should not exceed ${VIDEO_SIZE.DRILL_VIDEO}Mb. Supported files: MP4, AVI.`,
      stillUploading:
        'You still have video uploading in progress, if you want to create the event your videos wont be saved!',
      completed: 'Completed',
      addTeamFirst: 'You need to add a team first!',
      youHave: 'You have ',
      pendingInvitation: ' pending invitation',
      emptyCourtsList: 'No courts have been created yet!',
      noOptionsAvailable: 'No options available',
      createNewCourt: 'Create new court',
      inviteByEmail: 'Or invite by email:',
      ownershipRequested: 'You have requested ',
      toBecomeOwner: ' to become organisation Owner.',
      invitations: 'Invitations',
      OrgUsers: 'Organisation Users',
      apps: 'Apps',
      manageIntegrations: 'Manage your integrations',
      youtubeIntegrationText:
        "You can associate your organisation with YouTube channel to streamline your livestream setups. This integration automatically sets up the YouTube broadcast event so that you don't have to do it yourself.",
      youtubeIntegrationInfo: `(App’s) use and transfer of information received from Google APIs to any other app will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.`,
      linkYoutubeChannel: 'Link YouTube channel',
      unlink: 'Unlink',
      contentChildren: 'Content appropriate for kids:',
      promotionalContent: 'Contains promotional content',
      liveChat: 'Live Chat',
      liveChatReplay: 'Live Chat Replay',
      liveChatParticipants: 'Live Chat Participants',
      liveChatReactions: 'Live Chat Reactions',
      useYoutube: "Use the organisation's YouTube channel",
      differentYoutubeChannel: 'Use stream key URI',
      enterThumbnail: 'Upload Video Thumbnail',
      thumbnailEdit: 'The thumbnail image should not exceed 2MB',
      noPlays: 'No plays have been added!',
      enableStats: 'Enable Stats',
      showCards: 'Show Stats Cards',
      commentary: 'COMMENTARY',
      ambient: 'AMBIENT',
      streamNotStarted: 'The stream has not started yet. Please wait.',
      profileSettingsSub: 'Manage your personal information',
      removeVideo: 'Are you sure you want to remove this video from the training module?',
      bigFileAdded: 'The file is bigger than 20Mb',
      billingDetails: 'Billing Details',
      needInvoice: 'Are you a company? Need Invoice?',
      orgOwner:
        'You are the organisation owner. Closing your account will result in the deletion of all organisations you own.',
      cancelSubscriptionConfirmation: 'Are you sure you want to cancel your subscription?',
      period: 'Period',
      quarter: 'Q',
      youAreAdding: 'You are adding',
      coachingSeat: 'coaching seat',
      at: 'at',
      month: 'month',
      availableSeats: 'available seats',
      unauthorizedPageHeader: "Looks like you've been benched, let's get you back in the game!",
      unauthorizedPageMain:
        'Contact the organisation owner and check your subscription status or contact us.',
      unauthorizedTooltip: "You don't have access to this page. Check organisation's subscription!",
      supportBubbleHeader: 'Facing Issues?',
      supportBubbleText: 'Ask us anything or inform us for possible problems you encounter!',
      jerseyNumber: 'Jersey number: ',
      height: 'Height: ',
      positions: 'Positions: ',
      games: 'Games: ',
      allGames: 'All Games',
      lastGame: 'Last Game',
      last5Games: 'Last 5 Games',
      last10Games: 'Last 10 Games',
      seasonStats: ' Season Stats',
      gameStats: 'Game Stats',
      stats: 'Stats',
      navGames: 'Games',
      won: 'Won',
      lost: 'Lost',
      PIR: "Performance Index Rating, a measure of a player's overall statistical performance",
      GP: 'Games Played',
      GS: 'Games Started',
      MIN: 'Minutes Played',
      PTS: 'Points',
      '2P': 'Two-point Field Goal',
      '3P': 'Three-point Field Goal',
      FT: 'Free Throw',
      OR: 'Offensive Rebounds',
      DR: 'Defensive Rebounds',
      TR: 'Total Rebounds',
      AST: 'Assists',
      STL: 'Steals',
      TO: 'Turnovers',
      BLK: 'Blocks',
      BLKA: 'Blocks Against',
      FC: 'Fouls Committed',
      FD: 'Fouls Drawn',
      perQuarter: 'Per Quarter',
      endOfQuarter: 'End Of Quarter',
      addonDes: 'Description',
      allocatedSeats: 'Allocated Seats',
      totalCost: 'Total Cost',
      nextBillingDate: 'Next Billing',
      videoActivation: 'Video Activation',
      videoActivationMessage: 'You can ask everything you want to know about streaming videos.',
      updateSubscriptionConfirmation: 'You are about to update your subscription.',
      noActiveAddons:
        'You are about to remove the last addon from your subscription. Since this is the last addon, your subscription will be canceled until you activate it again.',
      priceSeat: 'Price/Seat: ',
      addOnActivation: 'Add-on Activation',
      addonType: 'Addon',
      chargedSeats: 'Charged Seats',
      addonInfo:
        'By default, you will take this addon with activation. If you want, you can select more users from the list below.',
      billingPaymentMessage:
        'Having an active subscription will lead you to pay only for the remaining month to activate this addon.',
      billingPaymentMessageInvite:
        'Having an active subscription will lead you to pay only for the remaining month to invite users.',
      reactivate: 'REACTIVATE',
      activateCoupon: 'ACTIVATE COUPON',
      publishTextA: 'Please ensure all fields are filled and the ',
      publishTextB: ' is written in English.',
      publishTextC:
        'Mismatch reserves the right to modify or unpublish your component if necessary.',
      deactivateAddonConfirmation: 'Are you sure you want to deactivate this add-on?',
      mainSubText: 'Re-subscribe to get access to activated addons.',
      seatsAvailable: 'Available Seats',
      totalPrice: 'Total price',
      socialConfMvp: 'Post Game MVP',
      socialConfAnnouncement: 'Post Game Announcement',
      socialConfQuarter: 'Post Game Quarter Announcement',
      socialConfResult: 'Post Game Result Announcement',
      socialConfBackground: "Post's background image",
      noAccounts: 'No Accounts Found',
      share: 'Share',
      copyToClipBoard: 'URL copied to clipboard!',
      didNotPlay: 'Did Not Play',
      score: 'Live Score',
      final: 'Final Score',
      continueWithFB: 'Continue with Facebook',
      metaIntegrationText:
        'You can connect your organisation with you Facebook account to automate the posting on your Facebook and Instagram feed. For every game you create, you get posts for game announcement, game mvp, game result and quarter result!',
      socialDes:
        "This Addon will affect all the club members. So you don't need to to add this addon to club users.",
      emailNotification:
        'Your recording is getting ready. You will receive an email with the download link, soon.',
      gameSummaryNotification:
        'Your game summary is getting ready. You will receive an email to download it.',
      downloadSummary: 'Download Summary',
      fansConfig: 'Fans Configuration',
      isFansEnabled: 'Is Fans Enabled',
    },
    formFields: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email Address',
      phoneNumber: 'Phone Number',
      password: 'Password',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password',
      birthday: 'Birth Date',
      acceptTerms: `I have read and agreed to <a style="color: #E0726E" href="${TERMS_AND_PRIVACY_URLS.TERMS_OF_USE}" target="_blank">Term of Services</a> and <a style="color: #E0726E" href="${TERMS_AND_PRIVACY_URLS.PRIVACY_POLICY}" target="_blank">Privacy Notice</a>`,
      name: 'Name',
      country: 'Country',
      city: 'City',
      logo: 'Logo',
      ageGroup: 'Select Age Group',
      gender: 'Select Gender',
      shortName: 'Short Name',
      teamShortName: 'Team Short Name',
      teamName: 'Team Name',
      teamExternalId: 'Team External Id',
      eventTitle: "Event's Title",
      from: 'From',
      date: 'Date',
      to: 'To',
      notes: 'Notes',
      topic: 'Topic',
      place: 'Place',
      startTime: 'Start Time',
      endTime: 'End Time',
      location: 'Location',
      opponent: 'Opponent',
      departure: 'Departure From',
      arrival: 'Arrival To',
      home: 'Home',
      away: 'Away',
      neutral: 'Neutral',
      court: 'Court',
      gym: 'Gym',
      other: 'Other',
      team: 'Team',
      teams: 'Teams',
      role: 'Role',
      intensity: 'Intensity',
      positions: 'Positions',
      tactics: 'Tactics',
      title: 'Title',
      courtName: 'Court Name',
      homeTeam: 'Home Team',
      awayTeam: 'Away Team',
      streamKeyURI: 'Stream Key URI',
      streamKeyURI2: 'Extra Stream Key URI',
      addAdditionalStreamKey: 'Add extra stream key URI',
      youtubeVideoTitle: 'Youtube Video Title',
      youtubeVisibility: 'User Visibility',
      orgName: 'Club Name',
      OrgRole: 'Organisation Role',
      userPermissions: 'User Permissions',
      streamVideo: 'Livestream',
      recordVideo: 'Record-Only',
      livestreamType: 'Livestream Type',
      panoramic: 'Panoramic',
      gameLite: 'Game Lite',
      gameFull: 'Game Full',
    },
    buttons: {
      average: 'Average',
      accumulate: 'Accumulate',
      login: 'LOG IN',
      signUp: 'SIGN UP',
      submit: 'SUBMIT',
      reset: 'RESET',
      update: 'UPDATE',
      save: 'SAVE',
      change: 'CHANGE',
      disableAccount: 'DISABLE ACCOUNT',
      disable: 'DISABLE',
      add: 'ADD',
      addSmall: 'Add',
      upload: 'UPLOAD',
      uploadImage: 'UPLOAD IMAGE',
      create: 'CREATE',
      createSmall: 'Create',
      newEvent: 'New Event',
      createAndNew: 'CREATE AND NEW',
      addPlayer: 'Add Player',
      invite: 'INVITE',
      addOrg: 'Add Organisation',
      addTeam: 'Add Team',
      addRole: 'Add Role',
      editPlayer: 'EDIT PLAYER',
      delete: 'DELETE',
      cancel: 'CANCEL',
      selectTeam: 'Select Team',
      public: 'PUBLIC',
      personal: 'PERSONAL',
      edit: 'Edit',
      close: 'CLOSE',
      remove: 'REMOVE',
      removeSmall: 'Remove',
      resend: 'RESEND',
      redirectLogin: 'Redirect to Login',
      resendMail: 'Resend e-mail',
      drillUpdateModePP: 'YES, THIS PLAN',
      drillUpdateModePlaybook: 'Yes, only in the Playbook',
      drillUpdateModeGeneral: 'NO, EVERYWHERE',
      chooseFile: 'CHOOSE A FILE',
      uploadOrgLogo: 'UPLOAD CLUB LOGO',
      uploadLeagueLogo: 'UPLOAD LEAGUE LOGO',
      uploadTeamLogo: 'UPLOAD TEAM LOGO',
      loadRecentSetups: 'Load Recent Setups',
      select: 'SELECT',
      stop: 'STOP',
      skip: 'SKIP',
      passOwnership: 'PASS OWNERSHIP',
      cancelRequest: 'CANCEL REQUEST',
      request: 'REQUEST',
      accept: 'ACCEPT',
      reject: 'REJECT',
      continue: 'CONTINUE',
      tryAgain: 'TRY AGAIN',
      retry: 'RETRY',
      changeCard: 'CHANGE CARD',
      addToPersonal: 'ADD TO PERSONAL',
      addToPersonalLower: 'Add to personal',
      choosePracticePlan: 'CHOOSE PRACTICE PLAN',
      newPracticePlan: 'NEW PRACTICE PLAN',
      newPractice: 'New Practice',
      ambient: 'AMBIENT',
      commentary: 'COMMENTARY',
      export: 'Export',
      watchStream: 'WATCH STREAM',
      watchVideo: 'WATCH VIDEO',
      startVideo: 'START LIVE',
      exit: 'EXIT',
      play: 'PLAY',
      pause: 'PAUSE',
      none: 'NONE',
      removeVideo: 'REMOVE VIDEO',
      cancelSubscription: 'CANCEL SUBSCRIPTION',
      no: 'NO',
      subscribe: 'SUBSCRIBE',
      practicePlayers: 'PLAYERS',
      activate: 'ACTIVATE',
      deactivate: 'DEACTIVATE',
      contactUs: 'CONTACT US',
      next: 'NEXT',
      prev: 'PREVIOUS',
      addUsers: 'ADD USERS',
      finish: 'FINISH',
      payNow: 'PAY NOW',
    },
    gridLinks: {
      login: 'Back to Log In',
      signUp: "Don't have an account? Sign Up",
      forgotPassword: 'Forgot password?',
    },
    errorMessages: {
      unknown: '',
      unauthorized: '',
      forbidden: '',
      invalidData: 'You must complete all the required fields.',
      serviceUnavailable: '',
      badRequest: '',
      s2sConflict: 'Sorry, the email or phone number is already in use.',
      businessError: 'Business Error',
      accountNotFound: 'Sorry, your account does not exist.',
      wrongPassword: 'Sorry, your account does not exist or your password is wrong.',
      emailAccountNotConfirmed: 'Sorry, your email account is not confirmed.',
      passwordResetTimeExpired: 'Sorry, password reset time has expired.',
      passwordNotFound: '',
      tokenNotValid: 'Invalid Token',
      tokenHasBeenUsed: 'Token has been used',
      identityNotFound: '',
      identityAlreadyConfirmed: '',
      identityIsPrimary: '',
      usernameExists: '',
      orgNameExists: 'Sorry, organisation name already exists.',
      confirmEmailError: 'Your email has not been confirmed',
      confirmEmailErrorRedirect: 'Redirecting to resend confirmation email page in',
      emailResentError: 'Resending verification mail failed. Try again!',
      emailResetPasswordError:
        'We could not find an account associated with the email address you provided. Please check the email address and try again. If you believe this is an error, please contact us at support@mismatch.gr.',
      logosLargePayloadError: 'Sorry, images exceed the uploaded limit of 16mb.',
      changePasswordError: 'Changing password failed.',
      updateTeamError: 'Update team failed. Please try again!',
      signUpError: 'Sorry, something went wrong!',
      selfInviteError: 'You cannot add your own email!',
      noUsersToInviteError: 'No users where added to invite!',
      doubleInviteError: 'You cannot add the same email twice!',
      alreadyOrganisationUserError:
        'The user is already an organisation user or you have already sent an invitation!',
      wrongEmailFormat: 'Wrong email input!',
      invitationSendError: 'Error while sending invitation!',
      invitationRejected: 'Invitation rejected!',
      maxVideosAdded: 'Cannot add more videos!',
      sameVideosAdded: 'Cannot add the same video!',
      wrong: 'Wrong password!',
      errorMessages: 'Something went wrong',
      connectionError: 'An error occured while loading data.',
      supportMessageFail: 'Support message sent unsuccessfully!',
      noStatsAvailable: 'No stats available.',
      noGamesAvailable: 'No Games available.',
      statsGameNotStarted: 'The game has not started yet.',
    },
    successMessages: {
      forgotPassword: 'An email with instructions is sent.',
      resetPassword: 'Your password has been changed, you will be redirected to Log In Page.',
      emailConfirm: 'Your email is confirmed, you will be redirected to Log In Page.',
      signUp: 'Your account has been created, you will be redirected to Log In Page.',
      profileData: 'Your profile data have been updated.',
      changePasswordSuccess: 'Your password has been changed successfully.',
      preferences: 'Your preferences have been changed.',
      disableAccount: 'Your account has been disabled, you will be redirected to Log In Page.',
      confirmEmailSuccess: 'Your email has been confirmed',
      emailResent: 'A new verification email has been sent!',
      emailResetPasswordSuccess: 'A reset-password email has been sent!',
      updateTeamSuccess: 'Your team has been updated successfully!',
      invitationAccepted: 'Invitation successfully accepted!',
      supportMessageSuccess: 'Support message sent successfully!',
    },
    navigation: {
      skip_nav: 'Skip to content',
      page_rows_per_page: 'Rows Per Page',
      page_range_info: 'Range Info',
      next: 'NEXT',
      no_results: 'NO RESULTS',
    },
    action: {
      add: 'Add',
      save: 'Save',
      delete: 'Delete',
      sort: 'SORT',
      add_filter: 'Add Filter',
      add_court: 'Add Court',
      search: 'Search',
      back: 'Back',
      refresh: 'Refresh',
      addPlayers: 'Add players to practice',
      selectAll: 'Select all',
      deselectAll: 'Deselect all',
      cancel: 'Cancel',
    },
    tour: {
      didYouKnow: 'Did you know?',
      didYouKnowIntro: '',
      welcome: 'Welcome to Mismatch App',
      welcomeIntro: 'Explore coach features for effective coaching. Ready to begin?',
      organisation: 'Organisation Section',
      orgIntro:
        'Create and edit organisations, invite other coaches, see all organisations you are a member of.',
      logo: "Team's Logo and Name",
      logoIntro:
        "Your team's name and logo. Easily revisit this page, or check the calendar, anytime by clicking on your organisation's logo.",
      roster: 'Team Management',
      rosterIntro:
        'Use the calendar to keep track of team events such as practices and games, manage <strong>team roster</strong>, design and plan effective training sessions with <strong>practice</strong> plans, and organise your plays into <strong>playbooks</strong>.',
      coach: 'Coach Corner',
      coachIntro:
        'Your coaching hub. Design <strong>drills</strong> for maximising team potential and design <strong>plays</strong> for achieving results.',
      drill: 'Drills',
      drillIntro:
        'On this page, view drills created by you or others. Create exercises for specific basketball skills.',
      public: 'Personal - Public Drill',
      publicIntro:
        'Use these buttons to distinguish personal drills (yours) or public drills shared by others, organisation-wide accessible.',
      createDrill: 'Create a Drill',
      createDrillIntro: 'Train the team with drills. Create a drill from here.',
      drillTitle: 'Drill Title',
      drillTitleIntro: 'The title of your drill.',
      drillDes: 'Drill Description',
      drillDesIntro:
        'Provide detailed drill info: exercise, skills, expectations, and player instructions.',
      drillGraphics: 'Graphics Addition',
      drillGraphicsIntro:
        'Enhance drill with court graphics using the <strong>Add</strong> button. Include lines, arrows, cones, balls, and more for a visual representation.',
      drillIntensity: 'Drill Intensity and Positions',
      drillIntensityIntro:
        'Set drill intensity level. <br/>Specify player positions for this drill.',
      drillTactics: 'Drill Tactics',
      drillTacticsIntro:
        'Specify associated tactic for this drill. Adding offensive or defensive tactics helps players understand the skills to train.',
      publicDrill: 'Drill Publication',
      publicDrillIntro:
        'Use the <strong>Publish</strong> button to share drills publicly, granting access to every coach in your organisation.',
      drillSave: 'Save and Delete',
      drillSaveIntro: 'After creating a drill, save it using the save button.',
      rosterMan: 'Roster Management',
      rosterManIntro:
        "Effortlessly manage your team's roster here. Add, edit, or remove players as needed.",
      playerList: 'Player List',
      playerListIntro:
        'All team players listed. Sort by name, jersey, height, age. Click a player to edit details.',
      addPlayer: 'Add Player',
      addPlayerIntro:
        'Click the <strong>Add Player</strong> button to seamlessly integrate new members into your roster.',
      plays: 'Plays',
      playsIntro:
        'The Plays section features pre-designed strategies and coordinated movements crafted to achieve specific objectives on the court.',
      publicPlay: 'Personal - Public Play',
      publicPlayIntro:
        'Use these buttons to distinguish personal plays (yours) or public plays shared, accessible to all in the organisation.',
      createPlay: 'Create a Play',
      createPlayIntro: 'Design your plays, describe them, and label them for easy filtering.',
      playTitle: 'Play Title',
      playTitleIntro: 'The title of your play.',
      playGraphics: 'Graphics Addition',
      playGraphicsIntro:
        'Enhance drill with court graphics using the <strong>Add</strong> button. Include lines, arrows, cones, balls, and more for a visual representation.',
      playDes: 'Play Description',
      playDesIntro: 'Provide detailed play info.',
      playTactics: 'Play Tactics',
      playTacticsIntro:
        'Specify tactic for play. Add offensive/defensive context for player understanding.',
      makePublicPlay: 'Play Publication',
      makePublicPlayIntro:
        'Make your plays public, allowing every other coach in your organisation to access them.',
      playSave: 'Save and Delete',
      playSaveIntro: 'After creating a play, save it using the <strong>Save</strong> button.',
      practice: 'Practices',
      practiceIntro: 'Explore planned team practices. Schedule for upcoming matches.',
      date: 'Date Range',
      dateIntro: 'You can specify the date range to view the planned events.',
      newPractice: 'New Practice',
      newPracticeIntro: 'Click this button to schedule a new practice for the team.',
      playBook: 'Playbook',
      playBookIntro:
        'A playbook comprises numerous plays, defining the tactics a team employs in all phases of a game.',
      addPlays: 'Add Plays',
      addPlaysIntro: 'Click this button to add plays to the playbook.',
      export: 'Export Playbook',
      exportIntro: 'Export playbook to PDF for practice distribution.',
    },
  },
  Password: {
    NotAllowedKeys: 'Invalid password',
  },
  GENERAL_SYSTEM_LANGUAGE: 'System Language',
  TEMPORAL_DATE_FORMAT: 'Date Format',
}

export default en
