import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import { useMutation, useQueryClient } from 'react-query'
import profileSettingsInfoEmailFormStyles from './profileSettingsInfoEmailForm.styles'
import MyEditIcon from '../../../assets/icons/MyEditIcon'
import MySimpleTextField from '../../../components/form/fields/MySimpleTextField'
import UpdateEmailModal from '../../../components/UpdateEmailModal/UpdateEmailModal'
import ContainerPadding from '../../../components/containerPadding/containerPadding'
import QueryKeys from '../../../utils/constants/queryKeys'
import updateUserData from '../../../Apis/user/updateUserData'
import userType from '../../../types/userType'
import MySnackbar from '../../../components/MySnackbar/MySnackbar'

const ProfileSettingsInfoEmailForm = ({ userData }) => {
  const { email } = userData
  const [openEmailModal, setOpenEmailModal] = useState(false)
  const translate = useTranslate()
  const queryClient = useQueryClient()
  const useStyles = makeStyles(profileSettingsInfoEmailFormStyles)
  const classes = useStyles()
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const updateUserEmail = useMutation(
    QueryKeys.UPDATE_USER_EMAIL_ADDRESS,
    (data) => updateUserData(data),
    {
      onSuccess: () => {
        setOpenEmailModal(false)
        queryClient.invalidateQueries(QueryKeys.GET_ACCOUNT_ME)
      },
      onError: (data) => {
        setErrorMessage(data?.response?.data?.details)
        setIsSnackbarOpen(true)
      },
    },
  )

  const onCloseSnackbar = () => setIsSnackbarOpen(false)

  // const {
  // createEmail,
  // pendingEmails,
  // resendConfirmationEmail,
  // deletePendingEmail
  // } = useProfileEmailFormQueries(setOpenEmailModal);
  // const newPendingEmails = pendingEmails && pendingEmails.filter(pendingEmail => !pendingEmail.primary);

  const editEmail = () => setOpenEmailModal(true)

  const onEmailSubmit = (values) => {
    const { electronicMail, password } = values
    updateUserEmail.mutate({
      email: electronicMail,
      password,
    })
  }

  return (
    <ContainerPadding padding='4% 1% 4% 1%'>
      <div className={classes.divFlex}>
        <MySimpleTextField value={email ?? ''} label={translate('ra.formFields.email')} disabled />
        <MyEditIcon onClick={editEmail} className={classes.editPassword} />
        <UpdateEmailModal
          open={openEmailModal}
          onClose={() => setOpenEmailModal(false)}
          onSubmit={onEmailSubmit}
          title={translate('ra.text.changeEmail')}
          subtitle={translate('ra.text.insertNewEmail')}
          firstValue='electronicMail'
          firstLabel={translate('ra.formFields.email')}
          secondValue='password'
          secondLabel={translate('ra.formFields.password')}
          loading={updateUserEmail.isLoading}
          isErrorOpen={isSnackbarOpen}
          onCloseError={onCloseSnackbar}
          errorMessage={errorMessage ?? translate('ra.errorMessages.unknown')}
        />
      </div>
      {/*<ConditionalRender renderValue={newPendingEmails && newPendingEmails.length > 0}>*/}
      {/*  <ProfileUserPendingEmails*/}
      {/*    pendingEmails={newPendingEmails}*/}
      {/*    resendConfirmationEmail={resendConfirmationEmail}*/}
      {/*    deletePendingEmail={deletePendingEmail}*/}
      {/*  />*/}
      {/*</ConditionalRender>*/}
    </ContainerPadding>
  )
}

ProfileSettingsInfoEmailForm.propTypes = {
  userData: userType,
}

export default ProfileSettingsInfoEmailForm
