import axiosInstance from '../axiosInterceptor/axiosInstance'

const patchFansConfigurations = (data) => {
  const { channelId, channel } = data
  const url = `/fans/channels/${channelId}`

  return axiosInstance.patch(url, channel)
}

export default patchFansConfigurations
