import isOrgAddonsValid from '../../../utils/helpers/isOrgAddonsValid'
import { USER_SCOPES } from '../../../utils/constants/userScopes'
import { Form, Formik } from 'formik'
import { makeStyles, Switch, Typography } from '@material-ui/core'
import React from 'react'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import fansConfigurationStyles from './fansConfiguration.styles'
import { useTranslate } from 'react-admin'
import { useMutation, useQuery } from 'react-query'
import QueryKeys from '../../../utils/constants/queryKeys'
import getChannel from '../../../Apis/Fans/getChannel'
import LoaderDots from '../../../components/loaders/LoaderDots/LoaderDots'
import patchFansConfigurations from '../../../Apis/Fans/patchFansConfigurations'
import localStorageHelper from '../../../utils/helpers/localStorageHelper'
import localStorageIds from '../../../utils/constants/localStorageIds'

const FansConfiguration = () => {
  const { localStorageValue: selectedOrg } = localStorageHelper(localStorageIds.SELECTED_ORG)
  const translate = useTranslate()
  const useStyles = makeStyles(fansConfigurationStyles)
  const classes = useStyles()

  const { channel } = selectedOrg
  console.log(channel)

  if (!isOrgAddonsValid([USER_SCOPES.FANS])) return null

  const { isLoading: fetchIsLoading, data: channelData } = useQuery(
    [QueryKeys.GET_CHANNEL],
    () => getChannel(channel?.id),
    { enabled: Boolean(channel?.id) },
  )

  const patchChannelMutation = useMutation((data) => patchFansConfigurations(data), {
    onSuccess: ({ data }, { resetForm }) => {
      resetForm({ values: { isFansEnabled: data?.isEnabled } })
    },
  })

  const onChangeSwitchState = (e, values, setValue) => {
    const valueName = e.target.name
    setValue(valueName, !values[valueName])
  }

  const onSubmitClick = (values, resetForm) => {
    const { isFansEnabled } = values
    const requestObj = {
      channelId: channel?.id,
      channel: { ...channelData, isEnabled: isFansEnabled },
      resetForm,
    }

    patchChannelMutation.mutate(requestObj)
  }

  if (fetchIsLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <LoaderDots />
      </div>
    )

  return (
    <Formik
      initialValues={{ isFansEnabled: channelData?.isEnabled }}
      onSubmit={(values, { resetForm }) => onSubmitClick(values, resetForm)}
    >
      {({ dirty, values, setFieldValue }) => (
        <>
          <Typography variant='h6'>{translate('ra.text.fansConfig')}</Typography>
          <Form
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {translate('ra.text.isFansEnabled')}
              <Switch
                className={classes.toggle}
                name='isFansEnabled'
                checked={values.isFansEnabled}
                onChange={(e) => onChangeSwitchState(e, values, setFieldValue)}
              />
            </div>
            <ButtonWithLoader
              label={translate('ra.buttons.save')}
              disabled={!dirty}
              loading={patchChannelMutation.isLoading}
            />
          </Form>
        </>
      )}
    </Formik>
  )
}

export default FansConfiguration
