import React from 'react'
import UnauthorizedPage from '../../pages/Unauthorized/UnauthorizedPage'
import isUserScopesValid from '../../utils/helpers/isUserScopesValid'
import isUserOrgRolesValid from '../../utils/helpers/isUserOrgRolesValid'
import orgIsInSubscriptionState from '../../utils/helpers/orgIsInSubscriptionState'
import SUBSCRIPTION_STATES from '../../utils/constants/subscriptionStates'
import UserHasOnlySpecificScopes from '../../utils/helpers/userHasOnlySpecificScopes'
import { USER_SCOPES } from '../../utils/constants/userScopes'
import isOrgAddonsValid from '../../utils/helpers/isOrgAddonsValid'

const withAuthHOC =
  (allowedOrgAddons, allowedScopes, allowedUserOrgRoles, payLocked) => (Component) => (props) => {
    if (!Component) return null

    if (orgIsInSubscriptionState(SUBSCRIPTION_STATES.INACTIVE) && payLocked) {
      return <UnauthorizedPage />
    }

    if (
      (!isUserScopesValid(allowedScopes) && !isOrgAddonsValid(allowedOrgAddons)) ||
      !isUserOrgRolesValid(allowedUserOrgRoles)
    ) {
      return <UnauthorizedPage />
    }

    return <Component {...props} />
  }

export default withAuthHOC
