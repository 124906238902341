import OrgSettingsPage from '../../pages/OrgSettings/OrgSettingsPage/OrgSettingsPage'
import TeamSettingsPage from '../../pages/TeamSettings/TeamSettingsPage/TeamSettingsPage'
import CalendarPageProvider from '../../pages/Calendar/CalendarPageProvider/CalendarPageProvider'
import TacticsPage from '../../pages/Tactics/TacticsPage'
import PracticesList from '../../pages/PracticesList/PracticesList'
import CreatePracticePlanContextWrapper from '../../components/CreatePracticePlanContextWrapper/CreatePracticePlanContextWrapper'
import ProfileSettingsPage from '../../pages/ProfileSettings/ProfileSettingsPage/ProfileSettingsPage'
import SignUpPage from '../../pages/SignUp/SignUpPage'
import ForgotPasswordPage from '../../pages/ForgotPassword/ForgotPasswordPage/ForgotPasswordPage'
import ResetPasswordPage from '../../pages/ResetPassword/ResetPasswordPage/ResetPasswordPage'
import CourtsPage from '../../pages/Courts/CourtsPage/CourtsPage'
import { USER_SCOPES } from './userScopes'
import DrillListPage from '../../pages/DrillList/DrillListPage/DrillListPage'
import DrillCreatePage from '../../pages/DrillCreate/DrillCreatePage/DrillCreatePage'
import DrillShowPage from '../../pages/DrillShow/DrillShowPage/DrillShowPage'
import SendConfirmationEmailPage from '../../pages/SendConfirmationEmail/SendConfirmationEmailPage'
import PlaybookList from '../../pages/PlaybookList/PlaybookList'
import SubscriptionPage from '../../pages/Subscription/SubscriptionPage/SubscriptionPage'
import USER_ORG_ROLES from './userOrgRoles'
import InvitationsList from '../../pages/InvitationsList/InvitationsList'
import OrgUsersPage from '../../pages/OrgUsers/OrgUsersPage/OrgUsersPage'
import PracticePlanShow from '../../pages/PracticePlanShow/PracticePlanShow'
import AppsPage from '../../pages/Apps/AppsPage/AppsPage'
import PlaybookCreate from '../../pages/PlaybookCreate/PlaybookCreate'
import LivestreamPlayerPage from '../../pages/LivestreamPlayerPage/LivestreamPlayerPage'
import PracticePlayersPage from '../../components/PracticePlayersPage/PracticePlayersPage'
import PracticePlayersShow from '../../pages/PracticePlayersShow/PracticePlayersShow'
import TeamStatisticsPage from '../../pages/Statistics/TeamStatisticsPage'
import PlayerStatisticsPage from '../../pages/Statistics/PlayerStatisticsPage'
import GameStatisticsPage from '../../pages/Statistics/GameStatisticsPage'

const { ALL, COACH, LIVESTREAM, STATS, ENGAGE } = USER_SCOPES
const { OWNER } = USER_ORG_ROLES

const ROUTES = [
  {
    exact: true,
    path: '/signUp',
    component: SignUpPage,
    noLayout: true,
    allowedScopes: ALL,
    allowedUserOrgRoles: ALL,
  },
  {
    exact: true,
    path: '/forgot-password',
    component: ForgotPasswordPage,
    noLayout: true,
    allowedScopes: ALL,
    allowedUserOrgRoles: ALL,
  },
  {
    exact: true,
    path: '/reset-password',
    component: ResetPasswordPage,
    noLayout: true,
    allowedScopes: ALL,
    allowedUserOrgRoles: ALL,
  },
  {
    exact: true,
    path: '/send-confirmation-email',
    component: SendConfirmationEmailPage,
    noLayout: true,
    allowedScopes: ALL,
    allowedUserOrgRoles: ALL,
  },
  {
    exact: true,
    path: '/profile',
    component: ProfileSettingsPage,
    noLayout: false,
    allowedScopes: ALL,
    allowedUserOrgRoles: ALL,
  },
  {
    exact: false,
    path: '/calendar',
    component: CalendarPageProvider,
    noLayout: false,
    allowedScopes: ALL,
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: false,
    path: '/teamCalendar',
    component: CalendarPageProvider,
    noLayout: false,
    allowedScopes: ALL,
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/orgSettings',
    component: OrgSettingsPage,
    noLayout: false,
    allowedScopes: ALL,
    allowedUserOrgRoles: [OWNER],
    payLocked: false,
  },
  {
    exact: true,
    path: '/teamSettings',
    component: TeamSettingsPage,
    noLayout: false,
    allowedScopes: ALL,
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/courts',
    component: CourtsPage,
    noLayout: false,
    allowedScopes: ALL,
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/orgUsers',
    component: OrgUsersPage,
    noLayout: false,
    allowedScopes: ALL,
    allowedUserOrgRoles: [OWNER],
    payLocked: false,
  },
  {
    exact: true,
    path: '/apps',
    component: AppsPage,
    noLayout: false,
    allowedOrgAddons: [ENGAGE],
    allowedScopes: [LIVESTREAM],
    allowedUserOrgRoles: [OWNER],
    payLocked: true,
  },
  {
    exact: true,
    path: '/invitationsList',
    component: InvitationsList,
    noLayout: false,
    allowedScopes: ALL,
    allowedUserOrgRoles: ALL,
    payLocked: false,
  },
  {
    exact: true,
    path: '/subscription',
    component: SubscriptionPage,
    noLayout: false,
    allowedScopes: ALL,
    allowedUserOrgRoles: [OWNER],
    payLocked: false,
  },
  {
    exact: true,
    path: '/tactics',
    component: TacticsPage,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/drills',
    component: DrillListPage,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/drills/create',
    component: DrillCreatePage,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/drills/:id',
    component: DrillCreatePage,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: false,
    path: '/drills/:id/show',
    component: DrillShowPage,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/plays',
    component: DrillListPage,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/plays/create',
    component: DrillCreatePage,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/plays/:id',
    component: DrillCreatePage,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: false,
    path: '/plays/:id/show',
    component: DrillShowPage,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: false,
    path: '/practice',
    component: PracticesList,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/practice-plan',
    component: CreatePracticePlanContextWrapper,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/practice-players',
    component: PracticePlayersPage,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/practice-players/:id/show',
    component: PracticePlayersShow,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/practice-plan/:id',
    component: CreatePracticePlanContextWrapper,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/practice-plan/:id/show',
    component: PracticePlanShow,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/playbook',
    component: PlaybookList,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: false,
    path: '/playbook/:id/show',
    component: DrillShowPage,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/playbook/create',
    component: PlaybookCreate,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/playbook/:id',
    component: PlaybookCreate,
    noLayout: false,
    allowedScopes: [COACH],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/livestream/:id',
    component: LivestreamPlayerPage,
    noLayout: true,
    allowedScopes: ALL,
    allowedUserOrgRoles: ALL,
  },
  {
    exact: true,
    path: '/stats',
    component: TeamStatisticsPage,
    noLayout: false,
    allowedScopes: [STATS],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/stats/player/:playerId',
    component: PlayerStatisticsPage,
    noLayout: false,
    allowedScopes: [STATS],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
  {
    exact: true,
    path: '/game/stats',
    component: GameStatisticsPage,
    noLayout: false,
    allowedScopes: [STATS],
    allowedUserOrgRoles: ALL,
    payLocked: true,
  },
]

export default ROUTES
