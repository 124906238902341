import * as React from 'react'
import { useTranslate } from 'react-admin'
import { Dialog, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import MyTextField from '../form/fields/MyTextField'
import updateEmailModalStyles from './updateEmailModal.styles'
import ButtonWithLoader from '../ui/ButtonWithLoader/ButtonWithLoader'
import TextButton from '../ui/TextButton/TextButton'
import MyTextFieldPassword from '../form/fields/MyTextFieldPassword'
import MySnackbar from '../MySnackbar/MySnackbar'

const UpdateEmailModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    title,
    subtitle,
    firstValue,
    firstLabel,
    secondValue,
    secondLabel,
    loading,
    isErrorOpen,
    onCloseError,
    errorMessage,
  } = props
  const translate = useTranslate()
  const useStyles = makeStyles(updateEmailModalStyles)
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
        <Typography variant='h5' className={classes.title}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            [firstValue]: '',
            [secondValue]: '',
          }}
          onSubmit={(data) => onSubmit(data)}
        >
          {(formik) => (
            <Form>
              <div className={classes.formWrapper}>
                <Typography variant='subtitle1' className={classes.subtitle}>
                  {subtitle}
                </Typography>
                <MyTextField label={firstLabel} name={firstValue} />
                <MyTextFieldPassword label={secondLabel} name={secondValue} />
              </div>
              <div className={classes.buttonWrapper}>
                <TextButton onClick={onClose} label={translate('ra.buttons.cancel')} />
                <ButtonWithLoader
                  label={translate('ra.buttons.change')}
                  disabled={!(formik.isValid && formik.dirty)}
                  loading={loading}
                />
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <MySnackbar
        isOpen={isErrorOpen}
        onClose={onCloseError}
        errorMessage={errorMessage ?? translate('ra.errorMessages.unknown')}
      />
    </Dialog>
  )
}

UpdateEmailModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  firstValue: PropTypes.string,
  firstLabel: PropTypes.string,
  secondValue: PropTypes.string,
  secondLabel: PropTypes.string,
  loading: PropTypes.bool,
}

export default UpdateEmailModal
