import React from 'react'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import BasketBall from '@material-ui/icons/SportsBasketball'
import connectionErrorPageStyles from '../connectionError/connectionErrorPageStyles'
import COLORS from '../../utils/constants/colors'

const NoGamesAvailablePage = () => {
  const translate = useTranslate()
  const useStyles = makeStyles(connectionErrorPageStyles)
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <BasketBall
        style={{
          width: '30%',
          height: '30%',
          fill: COLORS.orange,
          opacity: 0.5,
          padding: '60px',
        }}
      />
      <div>{translate('ra.errorMessages.noGamesAvailable')}</div>
    </div>
  )
}

export default NoGamesAvailablePage
