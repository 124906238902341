import COLORS from '../../../utils/constants/colors'
import calendarEventModalDimensions from '../../../utils/constants/calendarEventModalDimensions'

const calendarEventClickModalStyles = (coordinates) => {
  const { calendarEventModalWidth, calendarEventModalHeight } = calendarEventModalDimensions

  const adjustCoordinates = (oldCoordinates) => {
    const adjusted = { ...oldCoordinates }

    // Check for left, right edge overflow
    if (oldCoordinates.x < 0) {
      adjusted.x = 0
    } else if (oldCoordinates.x + calendarEventModalWidth > window.innerWidth) {
      adjusted.x = window.innerWidth - calendarEventModalWidth
    }

    // Check for top, bottom edge overflow
    if (oldCoordinates.y < 0) {
      adjusted.y = 0
    } else if (
      oldCoordinates.y + calendarEventModalHeight >
      window.innerHeight - calendarEventModalHeight
    ) {
      adjusted.y = window.innerHeight - 1.7 * calendarEventModalHeight
    }

    return adjusted
  }

  const adjustedCoordinates = adjustCoordinates(coordinates)

  return {
    dialog: {
      position: 'absolute!important',
      left: adjustedCoordinates.x,
      top: adjustedCoordinates.y,
      maxWidth: 'unset',
      width: calendarEventModalWidth,
    },
    dialogHeader: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: '10px',
    },
    orangeFill: {
      '&&': {
        width: 22,
        height: 22,
        fill: COLORS.orange,
      },
    },
    deleteIconDisabled: {
      cursor: 'not-allowed!important',
    },
    flexDiv: {
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
      marginBottom: '20px',
    },

    gameStatsDiv: {
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
    },
    statsDiv: {
      display: 'flex',
      alignItems: 'center',
      gap: '60px',
      marginBottom: '20px',
    },

    summaryDiv: {
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
      marginBottom: '20px',
    },
    flexDivSpread: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '20px',
      gap: '20px',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      gap: '20px',
    },
    title: {
      fontSize: '22px',
      fontWeight: '500',
    },
    notes: {
      color: COLORS.darkGrey,
      marginBottom: '20px',
    },
    copyAlert: {
      background: COLORS.backgroundGrey,
      padding: '3px 6px',
      fontSize: 12,
      fontWeight: 500,
    },
    gameSummaryAlert: {
      background: COLORS.backgroundGrey,
      padding: '3px 6px',
      fontSize: 12,
      fontWeight: 500,
    },
    practicePlanInfo: {
      marginBottom: '20px',
    },
  }
}

export default calendarEventClickModalStyles
